window.process = {
  ...window.process,
};

let storedState
if (process && process.env && process.env.REACT_APP_USE_STORED_STATE) {
  try {
    const fullState = window.localStorage.getItem('_state')
    if (fullState) {
      storedState = JSON.parse(fullState).formData
    }
  } catch (e) {
    console.error('Unable to set initial state from local storage:', e)
  }
}

const pristineState = {
  step: 'add-subs',
  familyStep: 'package',
  family: {
    completed: false,
    digitalSafety: null,
    ownerWantsSubscription: true,
    counts: {
      child: 0,
      adult: 2,
    },
    members: [{
      type: 'adult',
      set: false,
      id: Math.random().toString(),
      subscription: true,
    },
    {
      type: 'adult',
      set: false,
      id: Math.random().toString(),
      subscription: true,
    }],
  },
  bulkInvoiceDiscountActive: false,
  newPhoneSession: false,
  newFamilyPhoneSession: false,
  newPowerSession: false,
  singleExistingOwnerPhoneNo: null,
  phoneItems: [],
  powerItems: [],
  ssn: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  postalCode: '',
  postalArea: '',
  // product: null,
  // productPrice: null,
  // campaigns: [],
  // extraServices: [],
  // extraAbroadId: null,
  dateOfBirth: null,
  // childsName: '',
  // childsDateOfBirth: null,
  // simNumber: '',
  // portingDate: null,
  signature: null,
  // zone: null,
  currentPhone: null,
  operatorId: null,
  acceptBoxes: [],
  isSubmitting: false,
  combinedInvoice: null,
  departmentId: null,
  isWaitingForSMS: false,
  smsVerified: false,
  importResult: null,
  verificationId: null,
  clientId: null,
  serverId: null,
  saleRef: null,
}

const initialState = storedState || pristineState

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      const newFormData = { ...state, ...action.data }
      window.formData = newFormData
      return newFormData
    case 'RESET_FORM_DATA':
      // state.extraServices.splice(0, state.extraServices.length)
      // state.campaigns.splice(0, state.campaigns.length)
      try {
        window.localStorage.removeItem('_state')
      } catch (e) {
        console.error('Unable to remove local storage state:', e)
      }
      storedState = null
      state.acceptBoxes.splice(0, state.acceptBoxes.length)
      const newState = { ...state, ...pristineState }
      console.log('== RESET NOW', newState)
      return newState
    // return initialState
    default:
      return state;
  }
}